const Reviews = () => {
  return (
    <>
      <section className="reviews" id="reviews">
        <h1>What Guests say...</h1>
        <div className="reviews-wrapper">
          <div className="reviews-box">
            <div className="reviews-top">
              <i className="fa-solid fa-star"></i>
              <p>Good location in MacDuff.</p>
              <p>
                Quirky heritage fisherman's cottage in the heart of MacDuff.
              </p>
            </div>
            <div className="reviews-bottom">
              <span>Reviewed by Sheila from Canada</span>
              <span> October 2023</span>
            </div>
          </div>
          <div className="reviews-box">
            <div className="reviews-top">
              <i className="fa-solid fa-star"></i>
              <p>
                The property manager was very helpful, after we left,I realised
                my jewellery was,lost, he found it and sent it to us at.
             
                It was very comfortable for my family, clean and had everything
                we wanted.I will really recommend this property.
              </p>
            </div>
            <div className="reviews-bottom">
              <span>Reviewed by Babatunde</span>
              <span> August 2023</span>
            </div>
          </div>

          <div className="reviews-box">
            <div className="reviews-top">
              <i className="fa-solid fa-star"></i>
              <p className="long-comment">
                 My daughter, son and I had a lovely week staying at Rock
                Cottage. The cottage was very comfortable and spacious and
                provided a great base for exploring the joys of Northern
                Scotland. The bedrooms were comfortable, as was the sitting
                room. The kitchen was well equipped. There isn't anything that I
                would change and I would happily stay again. If anyone is
                thinking of visiting this area I would strongly recommend
                choosing RockStar Cottage.
              </p>
            </div>
            <div className="reviews-bottom">
              <span>Reviewed by Peter</span>
              <span> August 2023</span>
            </div>
          </div>

          <div className="reviews-box">
            <div className="reviews-top">
              <i className="fa-solid fa-star"></i>
              <p>
                {" "}
                The best for relax after work. Very comfortable and clean place.
              </p>
              <p> Great Rock'n'Roll Vibe.</p>
            </div>
            <div className="reviews-bottom">
              <span>Reviewed by Bartek, Tomek, Heniek from Poland</span>
              <span> May 2023</span>
            </div>
          </div>

          <div className="reviews-box">
            <div className="reviews-top">
              <i className="fa-solid fa-star"></i>
              <p>
                Very clean and comfortable with everything I needed for my seven
                day stay.
              </p>
            </div>
            <div className="reviews-bottom">
              <span>Reviewed by Sylvia</span>
              <span> May 2023</span>
            </div>
          </div>
          <div className="reviews-box">
            <div className="reviews-top">
              <i className="fa-solid fa-star"></i>
              <p>
                {" "}
                What a lovely house, home from home. My little boy especially
                loved the LED lights on the stairs.
              </p>
              <p>
                {" "}
                Great place to base ourselves, while we went off and explored.
              </p>
            </div>
            <div className="reviews-bottom">
              <span>Reviewed by Lisa, Terry, Noah</span>
              <span> April 2023</span>
            </div>
          </div>
          <div className="reviews-box">
            <div className="reviews-top">
              <i className="fa-solid fa-star"></i>
              <p>Cool decor, a lot bigger inside than you would think.</p>
              <p>
                {" "}
                Location was excellent! Right in the heart of town everything
                you need 2 minutes away
              </p>
            </div>
            <div className="reviews-bottom">
              <span>Reviewed by Stuart</span>
              <span> April 2023</span>
            </div>
          </div>

          <div className="reviews-box">
            <div className="reviews-top">
              <i className="fa-solid fa-star"></i>
              <p>
                {" "}
                Very clean and comfortable. Good kitchen and bathroom
                facilities.
              </p>
            </div>
            <div className="reviews-bottom">
              <span>Reviewed by Angus</span>
              <span> March 2023</span>
            </div>
          </div>

          <div className="reviews-box">
            <div className="reviews-top">
              <i className="fa-solid fa-star"></i>
              <p>Great owners, excellent contact.</p>
              <p>
                {" "}
                The perfect place to relax for the family. I would definitely
                recommend it.
              </p>
            </div>
            <div className="reviews-bottom">
              <span>Reviewed by Julita</span>
              <span> December 2022</span>
            </div>
          </div>

          <div className="reviews-box">
            <div className="reviews-top">
              <i className="fa-solid fa-star"></i>
              <p>Superb cottage, would certainly recommend.</p>
              <p>
                {" "}
                Hosts were very helpful and no issue was too big to help with.
              </p>
            </div>
            <div className="reviews-bottom">
              <span> Reviewed by Mark</span>
              <span> November 2022</span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Reviews;
