import React, { useState } from "react";

import rockstar from "../images/logo6.png";
import { useForm } from "react-hook-form";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleMailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };
  const clearForm = () => {
    setName("");
    setEmail("");
    setMessage("");
    console.log("wyczyszcono");
  };
  const sendMessage = (e) => {
    // e.preventDefault();
    // clearForm();
    if (name === "" || email === "" || message === "") {
      console.log("Puste pole");
      // alert("First name must be filled out");
      return false;
    } else {
      console.log("ok mail");
      // alert("Thank you! Your message was recieved!");
      // clearForm();
      return true;
    }
  };
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitSuccessful, errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    // fetch('https://hosting2061178.online.pro/build2/email.php', {
    //     method: 'POST',
    //     body: data,
    //     mode: 'no-cors',
    //   })
  };

  return (
    <>
      <section className="contact" id="contact">
        <div className="contact-wrapper">
          <div className="contact-title">
            <h1>
              Contact <span>us</span>
            </h1>
            <p>If you would like more information, we are here to help you.</p>
          </div>

          <div className="property-address">
            <p>
              <i className="far fa-address-card"></i>Address
            </p>
            <p>RockStar Cottage</p>
            <p>28 Market Street</p>
            <p>AB44 1QN Macduff</p>
            <p>
              <i className="fas fa-at"></i>email
            </p>
            <a href="mailto:info@rockstarcottage.co.uk">
              info@rockstarcottage.co.uk
            </a>
            {/* <p>info@rockstarcottage.co.uk</p> */}
            <img src={rockstar} alt="" />
          </div>

          {/* <form  className="contact-form" onSubmit={sendMessage}> */}
          <form
            action="https://hosting2061178.online.pro/build2/email.php"
            // action="email.php"
            method="post"
            className="contact-form"
            onSubmit={sendMessage}
            // onSubmit={handleSubmit(onSubmit)}
            control={control}
            onSuccess={() => {
              alert("Success");
            }}
            onError={() => {
              alert("error");
            }}
            // onSubmit={handleSubmit(
            //   async ({ formData, data, formDataJson, event }) => {
            //     await fetch(
            //       "https://hosting2061178.online.pro/build2/email.php",
            //       {
            //         method: "post",

            //         body: formData,
            //       }
            //     );
            //   }
            // )}
          >
            <div className="top-form-contact">
              <div className="name-top">
                <label htmlFor="fname">Your name:</label>
                <input
                  {...register("fname", { required: true })}
                  type="text"
                  id="fname"
                  name="fname"
                  value={name}
                  onChange={handleNameChange}
                />
                {errors.fname && <p>First name is required.</p>}
              </div>
              <span className="form-row honey-row">
                <label htmlFor="wasp">
                  Jeżeli jesteś człowiekiem, nie wypełniaj tego pola
                </label>
                <input type="text" name="wasp" id="wasp" />
              </span>
              <div className="email-top">
                <label htmlFor="slik">Your email:</label>
                <input
                  type="email"
                  id="slik"
                  name="slik"
                  value={email}
                  onChange={handleMailChange}
                />
              </div>
            </div>
            <label htmlFor="message">Message:</label>
            <textarea
              {...register("message", { required: true })}
              id="message"
              cols="30"
              rows="10"
              name="message"
              value={message}
              onChange={handleMessageChange}
            ></textarea>
            {errors.message && <p>Message is required.</p>}
            <button name="send_mail" type="submit">
              Send
            </button>
            {isSubmitSuccessful && <p>Form submit successful.</p>}
            {errors?.root?.server && <p>Form submit failed.</p>}
          </form>
        </div>
      </section>
    </>
  );
};

export default Contact;
