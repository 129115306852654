

const VisitorInfo = () => {
  return (
    <>
      <section className="about-rockstar" id="about-rockstar">
        <div className="visitor-info">
        
          <h1>
            About Rock<span>Star cottage</span>
          </h1>
          <p>
            Centrally located in Macduff, a coastal town in Aberdeenshire is
            RockStar Cottage, a family-friendly three-bedroom holiday home.
            Well-positioned near local amenities and the beach, whilst offering
            flexible sleeping arrangements and a well-equipped kitchen, the
            property provides the perfect base for a self-catered stay,
            welcoming five guests.Enter the homely living/dining room, featuring
            plush sofas, a dining table for five and a Smart TV, before heading
            into the kitchen, housing all expected appliances to rustle up
            delicious home cooked meals.Head outdoors for some fresh air to the
            rear garden, before returning indoors to freshen up in the
            ground-floor bathroom, featuring both a bath and shower.Once sleep
            calls, retire upstairs to find the three bedrooms, well-presented
            and dotted with storage, where you can make yourself at home ahead
            of your Aberdeenshire adventure; you'll find a double, a twin, and a
            single, offering flexibility for all. To ease your self-catered
            break, head into the town to stock up on the essentials or to visit
            one of many local eateries, before touring Macduff Marine Aquarium,
            hiking up Bridge of Alvah, playing a round of golf at Duff House
            Royal Golf Club, or attending a host of events at Duff House.
            Travelling towards the coastline, feel at one with nature by
            travelling through RSPB Scotland Troup Head, offering stunning cliff
            top views, or head west towards Portsoy Harbour, with the chance to
            discover the remains of Boyne Castle, or watch out for some dolphins
            at Moray Firth.Further popular attractions in the area include Fyvie
            Castle, a magnificent structure, housing antiques and heritage-rich
            exhibits, and Pitmedden Garden & Museum of Farming Life, where you
            can enjoy afternoon tea, follow the forest trail or stroll around
            its blooming gardens.Plan your next trip with RockStar Cottage as
            your base.
          </p>
          <div className="describe">
            <span>
              <i className="fa fa-house"></i>exclusive holiday home
            </span>
            <span>
              <i className="fa fa-people-group"></i>5 guests
            </span>
            <span>
              <i className="fa-sharp fa-solid fa-bed"></i>3 bedrooms
            </span>
            <span>
              <i className="fa-solid fa-bath"></i>1 bathroom
            </span>
            <span>
              <i className="fa-sharp fa-solid fa-kitchen-set"></i>1 kitchen
            </span>
          </div>
          <div className="apartment-details">
            <h2>
              Apartment <span>details</span>
            </h2>
            <ul className="apartment-details-list">
              <li><i className="fas fa-check-circle"></i> Three bedrooms: 1 x double, 1 x twin, 1 x single</li>
              <li><i className="fas fa-check-circle"></i>
                Ground-floor bathroom with bath, shower over, basin, and WC
              </li>
              <li><i className="fas fa-check-circle"></i>
                Fully fitted kitchen with electric oven and hob, microwave,
                kettle, toaster, fridge/freezer, washing machine, dishwasher,
                crockery, cutlery, pots, pans, glassware and cooking utensils.
              </li>
              <li><i className="fas fa-check-circle"></i>Utility with washing machine</li>
              <li><i className="fas fa-check-circle"></i>
                Bright and airy lounge/dining area. Ample seating for relaxing,
                smart TV with Freeview channels, table and 5 chairs for dining.
                There is a Amazon Alexa speaker.
              </li>
              <li><i className="fas fa-check-circle"></i>There is gas central heating and on-demand hot water.</li>
              <li><i className="fas fa-check-circle"></i>Highchair, travel cot, and stairgate available </li>
              <li><i className="fas fa-check-circle"></i>WiFi</li>
              <li><i className="fas fa-check-circle"></i>Ample roadside parking</li>
              <li><i className="fas fa-check-circle"></i>Rear garden with lawn, table and armchairs</li>
            </ul>
          </div>
          <div className="what-included">
            <h2>
              Whats<span> included</span>
            </h2>
            <ul className="what-included-list">
              <li><i className="fas fa-check-circle"></i>All bed linen and towels. </li>
              <li><i className="fas fa-check-circle"></i>Fuel and power.</li>
              <li><i className="fas fa-check-circle"></i>WiFi</li>
            </ul>
          </div>
          <div className="nearest">
            <h2>Nearest</h2>
            <ul className="nearest-list">
              <li><i className="fas fa-check-circle"></i>
                Duff House offers coffee with home baking or soup & a sandwich,
                and you can also take a tour of the house.
              </li>
              <li><i className="fas fa-check-circle"></i>
                Numerous shops, supermarkets, restaurants, cafes, bars, banks,
                etc all within a couple of minutes’ walk.
              </li>
              <li><i className="fas fa-check-circle"></i>Golf courses</li>
              <li><i className="fas fa-check-circle"></i>Dolphin Chip Shop on Skene Street</li>
              <li><i className="fas fa-check-circle"></i>Beach</li>
              <li><i className="fas fa-check-circle"></i>Macduff Marine Aquarium</li>
              <li><i className="fas fa-check-circle"></i>And many more...</li>
            </ul>
          </div>
          <div className="anything-else">
            <h2>
              Anything <span>Else</span>
            </h2>
            <ul className="anything-else-list">
              <li>
              <i className="fas fa-check-circle"></i>  Check-in time is any time after 3 PM and check-out time is 10
                AM.
              </li>
              <li><i className="fas fa-check-circle"></i>Sorry, no pets.</li>
              <li><i className="fas fa-check-circle"></i>Smoking is forbidden.</li>
            </ul>
          </div>
          <p className="ideal">Ideal for holidays, contractors, workers, business visits and more.</p>
        </div>
      </section>
    </>
  );
};

export default VisitorInfo;
