// import Scrollspy from "react-scrollspy";
import ScrollspyNav from "react-scrollspy-nav";
// import { NavLink, Link, Route, Routes } from "react-router-dom";
// import rockstar from "../images/rockstar.jpeg";

const MainMenu = (props) => {
  let className = "main-menu";
  if (!props.active) {
    className += " active-top-menu";
  }
  return (
    <>
      <ScrollspyNav
        scrollTargetIds={[
          "home",
          "about-rockstar",
          "location",
          "gallery",
          // "booking",
          "reviews",
          "contact",
        ]}
        offset={-80}
        activeNavClass="is-active"
        // scrollDuration="1000"
        // headerBackground="true"
      >
        <nav className={className}>
        {/* <img src={rockstar} alt="" /> */}
          <ul className="main-menu-list">
            <li>
              <a className="menu-item" href="#home"  onClick={props.closeMenu}>
                Home
              </a>
            </li>
            <li>
              <a className="menu-item" href="#about-rockstar" onClick={props.closeMenu}>
                Visitor info
              </a>
            </li>
            <li>
              <a className="menu-item" href="#location" onClick={props.closeMenu}>
                Location
              </a>
            </li>

            {/* <li>
          <a className="menu-item" href="#section-1"> Local attractions</a>
            
          </li> */}
            <li>
              <a className="menu-item" href="#gallery" onClick={props.closeMenu}>
                {" "}
                Gallery
              </a>
            </li>
            {/* <li>
              <a className="menu-item" href="#booking" onClick={props.closeMenu}>
                Booking
              </a>
            </li> */}
            <li>
              <a className="menu-item" href="#reviews" onClick={props.closeMenu}>
                Reviews
              </a>
            </li>
            <li>
              <a className="menu-item" href="#contact" onClick={props.closeMenu}>
                Contact
              </a>
            </li>
          </ul>
        </nav>
      </ScrollspyNav>
    </>
  );
};

export default MainMenu;
