import React from "react";
import welcome from "../images/welcome-1.jpeg";
const Location = (props) => {
  return (
    <>
      <section className="location" id="location">
      <h1>Location</h1>
        <div className="welcome">
          
          <img src={welcome} alt="Macduff location" />
          <p>
            MacDuff is situated on Banff Bay and overlooks its neighbouring town
            of Banff, just across the estuary of the River Deveron, on the Moray
            Coast in the north east of Scotland. Macduff enjoys stunning sea
            views and wonderful sunsets and the architecture reflects its
            maritime heritage. The north east coast of Scotland is a hidden gem,
            with its atmospheric fishing villages dotted along the coastline,
            making it a wonderful place for easy exploration. MacDuff has a busy
            working harbour. The town itself has a lot to offer with an
            excellent selection of shops and good restaurants and a visit to the
            MacDuff Aquarium is a must for seeing the species found in the
            waters of the Moray Firth. For Golfers, the Royal Tarlair course is
            a challenge as it is situated on precipitous cliffs and lost balls
            are never retrieved! Macduff is a wonderful town and an ideal
            holiday base.
          </p>
        </div>
        <div className="map">
          <iframe
            title="RockStarCottage"
            // width="100%"
            // height="600"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=28%20Market%20Street,%20Macduff,%20AB441QN,%20Scotland+(RockStar%20cottage)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          >
            <a href="https://www.maps.ie/distance-area-calculator.html">
              measure acres/hectares on map
            </a>
          </iframe>
        </div>
      </section>
    </>
  );
};

export default Location;
