import React,{useState} from "react";
import lightHouse from "../images/lightHouse.jpg";
// import bridge from "../images/bridge.jpg";
import mac from "../images/mac2.jpg";




const Header = () => {
  const [background, setBackground] = useState("");
  const headerImages = [lightHouse, mac];
  const drawImage = () => {
    let index = Math.floor(Math.random() * headerImages.length);
    return headerImages[index];
  };
  const imgStyle = {
    background: `url(${drawImage()})`,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "60vh",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundAttachment: "scroll",
    zIndex: 0,
    
  };
  return (
    <>
    {/* style={imgStyle} */}
      <section className="top-hero info-hero"  id="home">
        <div className="white-block white-block-left"></div>
      </section>
    </>
  );
};

export default Header;
