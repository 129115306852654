import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import "../styles/style.scss";
// import Content from "./Content";
import MainMenu from "./MainMenu";
import Header from "./Header";
import VisitorInfo from "./VisitorInfo";
import Footer from "./Footer";
import Location from "./Location";
import Gallery from "./Gallery";
import Reviews from "./Reviews";
import ScrollSpy from "react-ui-scrollspy";
import Contact from "./Contact";
import Form from "./Form";

const RockStarApp = (props) => {
  const [burgerIcon, setBurgerIcon] = useState(true);

  const handleClickBurgerIcon = () => {
    if (burgerIcon) {
      setBurgerIcon(!burgerIcon);
    } else setBurgerIcon(!burgerIcon);
  };
 
      return (
    <>
      <div className="wrapper-app">
        <BrowserRouter>
          <ScrollSpy>
            <MainMenu active={burgerIcon} closeMenu={handleClickBurgerIcon} />
            <div className="top-burger-menu">
              <h1>
                Rock<span>Star cottage</span>
              </h1>
              <div onClick={handleClickBurgerIcon} className="burger ">
                {burgerIcon ? (
                  <i className="fas fa-bars"></i>
                ) : (
                  <i className="fa fa-close"></i>
                )}
              </div>
            </div>
            <Header />
            <VisitorInfo />
            {/* <a className="home" href="index.html"> <div className="home-box-up"> <i className="fa fa-angle-up"></i></div></a> */}
            <Location />
            <Gallery />
            <Reviews />
            <Contact />
            {/* <div className="formik">
            <Form />
            </div> */}
            <Footer />
          </ScrollSpy>
        </BrowserRouter>
      </div>
    </>
  );
};
export default RockStarApp;
