import ImageGallery from "react-image-gallery";
import thumbimg1 from "../images/rockstar/thumbimg1.jpg";
import img1 from "../images/rockstar/img1.jpg";
import thumbimg2 from "../images/rockstar/thumbimg2.jpeg";
import img2 from "../images/rockstar/img2.jpg";
import thumbimg3 from "../images/rockstar/thumbimg3.jpg";
import img3 from "../images/rockstar/img3.jpg";
import thumbimg4 from "../images/rockstar/thumbimg4.jpg";
import img4 from "../images/rockstar/img4.jpg";
import thumbimg5 from "../images/rockstar/thumbimg5.jpg";
import img5 from "../images/rockstar/img5.jpg";
import thumbimg6 from "../images/rockstar/thumbimg6.jpg";
import img6 from "../images/rockstar/img6.jpg";
import thumbimg7 from "../images/rockstar/thumbimg7.jpg";
import img7 from "../images/rockstar/img7.jpg";
import thumbimg8 from "../images/rockstar/thumbimg8.jpg";
import img8 from "../images/rockstar/img8.jpg";
import thumbimg9 from "../images/rockstar/thumbimg9.jpg";
import img9 from "../images/rockstar/img9.jpg";
import thumbimg10 from "../images/rockstar/thumbimg10.jpg";
import img10 from "../images/rockstar/img10.jpg";
import thumbimg11 from "../images/rockstar/thumbimg11.jpg";
import img11 from "../images/rockstar/img11.jpg";
import thumbimg12 from "../images/rockstar/thumbimg12.jpg";
import img12 from "../images/rockstar/img12.jpg";
import thumbimg13 from "../images/rockstar/thumbimg13.jpg";
import img13 from "../images/rockstar/img13.jpg";
import thumbimg14 from "../images/rockstar/thumbimg14.jpg";
import img14 from "../images/rockstar/img14.jpg";
import thumbimg15 from "../images/rockstar/thumbimg15.jpg";
import img15 from "../images/rockstar/img15.jpg";
import thumbimg16 from "../images/rockstar/thumbimg16.jpg";
import img16 from "../images/rockstar/img16.jpg";
import thumbimg17 from "../images/rockstar/thumbimg17.jpg";
import img17 from "../images/rockstar/img17.jpg";
import thumbimg18 from "../images/rockstar/thumbimg18.jpg";
import img18 from "../images/rockstar/img18.jpg";
import thumbimg19 from "../images/rockstar/thumbimg19.jpg";
import img19 from "../images/rockstar/img19.jpg";
import thumbimg20 from "../images/rockstar/thumbimg20.jpg";
import img20 from "../images/rockstar/img20.jpg";
import thumbimg22 from "../images/rockstar/thumbimg22.jpg";
import img22 from "../images/rockstar/img22.jpg";
import thumbimg23 from "../images/rockstar/thumbimg23.jpg";
import img23 from "../images/rockstar/img23.jpg";
import thumbimg24 from "../images/rockstar/thumbimg24.jpg";
import img24 from "../images/rockstar/img24.jpg";
import thumbimg25 from "../images/rockstar/thumbimg25.jpg";
import img25 from "../images/rockstar/img25.jpg";
import thumbimg26 from "../images/rockstar/thumbimg26.jpg";
import img26 from "../images/rockstar/img26.jpg";
import thumbimg27 from "../images/rockstar/thumbimg27.jpg";
import img27 from "../images/rockstar/img27.jpg";
import thumbimg28 from "../images/rockstar/thumbimg28.jpg";
import img28 from "../images/rockstar/img28.jpg";
import thumbimg29 from "../images/rockstar/thumbimg29.jpg";
import img29 from "../images/rockstar/img29.jpg";
import thumbimg30 from "../images/rockstar/thumbimg30.jpg";
import img30 from "../images/rockstar/img30.jpg";

import thumbimg40 from "../images/rockstar/thumbimg40.jpg";
import img40 from "../images/rockstar/img40.jpg";
import thumbimg41 from "../images/rockstar/thumbimg41.jpg";
import img41 from "../images/rockstar/img41.jpg";
import thumbimg45 from "../images/rockstar/thumbimg45.jpg";
import img45 from "../images/rockstar/img45.jpg";
import thumbimg46 from "../images/rockstar/thumbimg46.jpg";
import img46 from "../images/rockstar/img46.jpg";
import thumbimg50 from "../images/rockstar/thumbimg50.jpg";
import img50 from "../images/rockstar/img50.jpg";
import thumbimg51 from "../images/rockstar/thumbimg51.jpg";
import img51 from "../images/rockstar/img51.jpg";
import thumbimg52 from "../images/rockstar/thumbimg52.jpg";
import img52 from "../images/rockstar/img52.jpg";
import thumbimg53 from "../images/rockstar/thumbimg53.jpg";
import img53 from "../images/rockstar/img53.jpg";
import thumbimg54 from "../images/rockstar/thumbimg54.jpg";
import img54 from "../images/rockstar/img54.jpg";
import thumbimg55 from "../images/rockstar/thumbimg55.jpg";
import img55 from "../images/rockstar/img55.jpg";
import thumbimg56 from "../images/rockstar/thumbimg56.jpg";
import img56 from "../images/rockstar/img56.jpg";

const images = [
  {
    original: img1,
    thumbnail: thumbimg1,
    originalHeight: 490,
   
  },
  {
    // description: " Buddy, Dog given to the king of England",
    original: img2,
    thumbnail: thumbimg2,
    originalHeight: 490,
  },
  {
    original: img3,
    thumbnail: thumbimg3,
    originalHeight: 490,
  },
  {
    original: img4,
    thumbnail: thumbimg4,
    originalHeight: 490,
  },
  {
    original: img5,
    thumbnail: thumbimg5,
    originalHeight: 490,
  },
  {
    original: img6,
    thumbnail: thumbimg6,
    originalHeight: 490,
  },
  {
    original: img7,
    thumbnail: thumbimg7,
    originalHeight: 490,
  },
  {
    original: img8,
    thumbnail: thumbimg8,
    originalHeight: 490,
  },
  {
    original: img9,
    thumbnail: thumbimg9,
    originalHeight: 490,
  },
  {
    original: img10,
    thumbnail: thumbimg10,
    originalHeight: 490,
  },
  {
    original: img11,
    thumbnail: thumbimg11,
    originalHeight: 490,
  },
  {
    original: img12,
    thumbnail: thumbimg12,
    originalHeight: 490,
  },
  {
    original: img13,
    thumbnail: thumbimg13,
    originalHeight: 490,
  },
  {
    original: img14,
    thumbnail: thumbimg14,
    originalHeight: 490,
  },
  {
    original: img15,
    thumbnail: thumbimg15,
    originalHeight: 490,
  },
  {
    original: img16,
    thumbnail: thumbimg16,
    originalHeight: 490,
  },
  {
    original: img17,
    thumbnail: thumbimg17,
    originalHeight: 490,
  },
  {
    original: img18,
    thumbnail: thumbimg18,
    originalHeight: 490,
  },
  {
    original: img19,
    thumbnail: thumbimg19,
    originalHeight: 490,
  },
  {
    original: img20,
    thumbnail: thumbimg20,
    originalHeight: 490,
  },
  
  {
    original: img22,
    thumbnail: thumbimg22,
    originalHeight: 490,
  },
  {
    original: img50,
    thumbnail: thumbimg50,
    originalHeight: 490,
  },
  {
    original: img51,
    thumbnail: thumbimg51,
    originalHeight: 490,
  },
  {
    original: img52,
    thumbnail: thumbimg52,
    originalHeight: 490,
  },
  {
    original: img53,
    thumbnail: thumbimg53,
    originalHeight: 490,
  },
  {
    original: img54,
    thumbnail: thumbimg54,
    originalHeight: 490,
  },
  {
    original: img55,
    thumbnail: thumbimg55,
    originalHeight: 490,
  },
  
  {
    original: img56,
    thumbnail: thumbimg56,
    originalHeight: 490,
  },

  {
    original: img40,
    thumbnail: thumbimg40,
    originalHeight: 490,
  },
  {
    original: img41,
    thumbnail: thumbimg41,
    originalHeight: 490,
  },
  {
    original: img23,
    thumbnail: thumbimg23,
    originalHeight: 490,
  },
  {
    original: img24,
    thumbnail: thumbimg24,
    originalHeight: 490,
  },
  {
    original: img25,
    thumbnail: thumbimg25,
    originalHeight: 490,
  },
  {
    original: img26,
    thumbnail: thumbimg26,
    originalHeight: 490,
  },
  {
    original: img27,
    thumbnail: thumbimg27,
    originalHeight: 490,
  },
  {
    original: img28,
    thumbnail: thumbimg28,
    originalHeight: 490,
  },
  {
    original: img29,
    thumbnail: thumbimg29,
    originalHeight: 490,
  },
  {
    original: img30,
    thumbnail: thumbimg30,
    originalHeight: 490,
  },
 
  {
    original: img45,
    thumbnail: thumbimg45,
    originalHeight: 490,
  },
  {
    original: img46,
    thumbnail: thumbimg46,
    originalHeight: 490,
  },
];

const Gallery = () => {
  return (
    <>
      <section className="gallery" id="gallery">
        <div className="image-gallery">
          <h1>
            Gal<span>lery</span>{" "}
          </h1>
          <ImageGallery
            items={images}
            showIndex={true}
            showThumbnails={true}
            showBullets={false}
            // originalHeight={3000}
            // lazyLoad={true}
            // showPlayButton={false}
          />
        </div>
        <div className="side-title">
          <h2>
            Rock<span>Star</span> cottage
          </h2>
          <h2>and surroundings gallery</h2>
        </div>
      </section>
    </>
  );
};

export default Gallery;
